// Storage flags
export const STORAGE_APP_ID = 'APP_ID'
export const STORAGE_UUID = 'UUID'
export const STORAGE_TOKEN = 'TOKEN'
export const STORAGE_DEBUG = 'DEBUG'
export const STORAGE_HIDE_CHAPTER_READ_TIPS = 'HIDE_CHAPTER_READ_TIPS'
export const STORAGE_CUSTOM_THEME = 'CUSTOM_THEME'
export const STORAGE_ACTIVITY_MAP = 'ACTIVITY_MAP'
export const STORAGE_RECHARGE_INTERCEPTION = 'RECHARGE_INTERCEPTION'
export const STORAGE_SIGN_PRODUCT_ID = 'SIGN_PRODUCT_ID'
export const STORAGE_READ_CHAPTER_LAST = 'READ_CHAPTER_LAST'
export const STORAGE_TRACK_VALUE_FIRST_ORIGIN_MAP = 'TRACK_VALUE_FIRST_ORIGIN_MAP'
export const STORAGE_TRACK_VALUE_ORIGIN_NAME_MAP = 'TRACK_VALUE_ORIGIN_NAME_MAP'
export const STORAGE_TRACK_VALUE_CHANNEL_NAME_MAP = 'TRACK_VALUE_CHANNEL_NAME_MAP'

// UserAgent
const ua = navigator.userAgent
export const isIOS = /(ios|ipad|iphone)/i.test(ua) || (ua.includes('macintosh') && 'ontouchend' in document)
export const isAndroid = /android/i.test(ua)
export const isSogou = /sogoumse|sogoumobilebrowser/i.test(ua)
export const isWechat = /micromessenger/i.test(ua)
export const isQQ = /qq\//i.test(ua)
export const isWeibo = /weibo/i.test(ua)
export const isSafari
  = ua.includes('Safari')
  && !ua.includes('Chrome')
  && !ua.includes('Sogou')
  && !ua.includes('CriOS')
  && !ua.includes('QQBrowser')
  && !ua.includes('FingerBrowser')
  && !/firefox|iceweasel|fxios/i.test(ua)

/** Enums */
/** 内容类型, 男频/女频 */
export enum CONTENT_CHANNEL {
  MAN = 1,
  WOMAN,
}

/** 微信 OAuth `code` 来源 */
export enum CODE_SOURCE {
  /** 兜底公众号 */
  FALLBACK = 2,
  /** 目标公众号 */
  TARGET = 3,
}

/** 首页模板样式 */
export enum COLUMN_TYPE {
  /** 导航栏 (金刚位) */
  NAV = 1,
  /** 轮播图 */
  BANNER,
  /** 横1 + 竖4 */
  ROW_1_COL_4,
  /** 竖3 限免 */
  COL_3_FREE,
  /** 横4 */
  ROW_4,
  /** 竖3 常规 */
  COL_3_NORMAL,
  /** 最热榜单 */
  HOT,
}
export enum RESOURCE_TYPE {
  CONTENT = 1,
}

/** 活动状态，0: 正常, 1: 未开始, 2: 暂停, 3: 已结束, 4: 已下架(禁用） */
export enum ACTIVITY_STATUS {
  NORMAL = 0,
  NOT_START,
  PAUSE,
  FINISHED,
  OFFLINE,
}

/** 活动类型 */
export enum ACTIVITY_TYPE {
  /** 充值 */
  RECHARGE = 1,
  /** 赠送 */
  GIFT_COIN,
  /** 运营位充值活动 */
  OPERATIONAL,
}

/** 活动页面位置 */
export enum ACTIVITY_LOCATION_PAGE {
  /** 首页 */
  INDEX = 1,
  /** 女频 */
  WOMEN = 1 << 1,
  /** 历史记录页 */
  HISTORY = 1 << 2,
  /** 个人中心页 */
  MEMBER = 1 << 3,
  /** 分类 */
  CATEGORY = 1 << 4,
  /** 阅读页 */
  CHAPTER = 1 << 5,
}

export enum OPERATIONAL_POSITION {
  /** 全部 */
  ALL = 1,
  /** 最近阅读页 */
  HISTORY,
  /** 首页 */
  HOME,
  /** 阅读页 */
  CHAPTER,
  /** 福利页 */
  WELFARE,
  /** 个人中心页 */
  MEMBER,
}

export enum PAY_LOCATION_PAGE {
  NONE = 0,
  CHAPTER,
  MEMBER,
  ACTIVITY,
  VIP,
}

export enum CHAPTER_LOCK {
  LOCK = 2,
}

export enum TASK_STATE {
  INITIAL = 0,
  WAIT_FINISHE = 2,
  WAIT_RECEIVE = 3,
  FINISHED = 4,
}
export enum TASK_TYPE {
  SUBSCRIBE = 6,
}

export enum ERROR_PAGE_LEVEL {
  DEFAULT = '0',
  FATAL = '1',
}

// 商品类型 1 金币充值 2 vip充值
export enum PRODUCT_TYPE {
  COIN = 1,
  VIP = 2,
  SVIP = 3,
}

// https://doc.weixin.qq.com/sheet/e3_AbAA8Ab1AA0MTQcebGWTIyH9wx7pC?scode=AAsAkAdiAAs002NeV3AbAA8Ab1AA0&tab=lmg39t
export enum TRACK_CHANNEL_NAME_VALUE {
  /** 投放书籍 */
  PROMOTION = 1,
  /** 广告位 */
  BANNER = 3,
  /** 主编推荐 */
  CHIEF_RECOMMEND = 4,
  /** 全网热推 */
  HOT_RECOMMEND = 5,
  /** 强力推荐 */
  STRONG_RECOMMEND = 6,
  /** 本周主打 */
  MAIN_RECOMMEND = 7,
  /** 精品好文 */
  HIGH_QUALITY = 8,
  /** 搜索列表 */
  SEARCH_LIST = 9,
  /** 阅读记录 */
  READ_HISTORY = 10,
  /** 文末推荐 */
  CHAPTER_END_RECOMMEND = 11,
  /** 运营位浮窗 */
  OPERATIONAL_FLOAT = 12,
  /** 分类 */
  CATEGORY = 100,
}
